<template>
  <div class="content">
    <div class="contest-submit">
      <div class="wrap">
        <div class="headline clearfix">
          <h1 class="left">多图投稿</h1>
          <a
            class="btn-back right"
            href="javascript:;"
            @click="$router.history.go(-1)"
            >返回</a
          >
        </div>
        <div class="contest-con">
          <el-form :model="form" ref="form" :rules="rules" label-width="120px">
            <div class="info-box clearfix">
              <div class="left">
                <el-form-item label="作品名称" prop="name">
                  <el-input
                    v-model="form.mname"
                    placeholder="请输入15字以内的作品名称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="拍摄日期区间" prop="shoot_daterange">
                  <el-date-picker
                      style="width: 100%;"
                      v-model="form.shoot_daterange"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="拍摄日期" prop="shoot_date">
                  <el-date-picker
                    style="width: 100%;"
                    v-model="form.shoot_date"
                    type="date"
                    placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="作品拍摄地" prop="shoot_address">
                  <el-input
                          v-model="form.shoot_address"
                          placeholder="请输入作品拍摄地"
                  ></el-input>
                </el-form-item>
                <el-form-item label="作品描述" prop="desc">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="form.remark"
                    maxlength="1000"
                    show-word-limit
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="活动区域">
                  <el-select style="width: 100%;" v-model="form.region" placeholder="请选择活动区域">
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="即时配送">
                  <el-switch v-model="form.delivery"></el-switch>
                </el-form-item>
                <el-form-item label="活动性质">
                  <el-checkbox-group v-model="form.type">
                    <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
                    <el-checkbox label="地推活动" name="type"></el-checkbox>
                    <el-checkbox label="线下主题活动" name="type"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="特殊资源">
                  <el-radio-group v-model="form.resource">
                    <el-radio label="线上品牌商赞助"></el-radio>
                    <el-radio label="线下场地免费"></el-radio>
                  </el-radio-group>
                </el-form-item>
                <div class="info-item" style="display: block;">
                  <a
                    class="btn btn-bj"
                    href="javascript:void(0)"
                    @click="triggerUpload()"
                    >从本机选择图片</a
                  >
                  <input
                    id="file_btn"
                    type="file"
                    name="file[]"
                    accept="image/png,image/jpeg"
                    @change="addImg($event)"
                    style="display: none"
                    multiple
                  />
                  <a class="btn btn-tk" href="javascript:;" @click="showGallery"
                  >从我的图库中选择图片</a
                  >
                  <p class="tips-txt" v-show="msg">
                    {{msg}}
                  </p>
                  <p class="tips-txt">
                    （本赛事仅支持{{gourp_limit.typestr}}格式且不大于{{gourp_limit.size}}M的图片）
                  </p>
                  <div class="agree-l-b">
                    <label>
                      <input
                        style="margin-right:5px;"
                        class="check"
                        type="checkbox"
                        :checked="isPromise"
                        @click="isPromise = !isPromise"
                      />
                      <span class="text">本人承诺递交的记录类作品未对原始影像进行违规处理</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="hd2 clearfix">
                  <p class="left">已上传图片列表</p>
                  <p class="right">
                    <span class="imgCount">0</span>/20
                  </p>
                </div>
                <div class="bd2 drag-list">
                  <draggable v-model="uploadList" animation="500" chosen-class="chosenDragItem" @update="dragUpdate" v-if="uploadList.length>0">
                    <div class="drag-item-wrap" v-for="(item, index) in uploadList" :key="index">
                      <div class="drag-item">
                        <el-image class="img" fit="cover" :src="item.img" :preview-src-list="[item.img]"></el-image>
                        <div class="desc">
                          <el-input class="ipt" type="textarea" v-model="item.desc" placeholder="请输入内容"></el-input>
                        </div>
                        <div class="btn-group">
                          <i class="el-icon-arrow-up" :style="{cursor: index === 0 ? 'not-allowed' : 'pointer'}" @click.stop="moveUpDragItem(index)"></i>
                          <i class="el-icon-close" @click.stop="removeDragItem(index)"></i>
                          <i class="el-icon-arrow-down" :style="{cursor: index === uploadList.length-1 ? 'not-allowed' : 'pointer'}" @click.stop="moveDownDragItem(index)"></i>
                        </div>
                      </div>
                      <div class="tips" style="color: red;" v-if="item.tips">*图片格式错误，请重新上传</div>
                    </div>
                  </draggable>
                  <el-empty description="图片待上传" v-else></el-empty>
                </div>
              </div>
            </div>
            <div class="submit-box">
              <div class="submit-item">
                <div style="margin-bottom:15px;">
                  <label>
                    <input
                      style="margin-right:5px;"
                      class="check"
                      type="checkbox"
                      :checked="isPromise"
                      @click="isPromise = !isPromise"
                    />
                    <span class="text" style="font-size:18px;">本人承诺递交的记录类作品未对原始影像进行违规处理</span>
                  </label>
                </div>
                <label>
                  <input
                          class="check"
                          type="checkbox"
                          :checked="isAgreement"
                          @click="isAgreement = !isAgreement"
                  />
                  <span class="text">我同意将该图片同步到app发现中</span>
                </label>
              </div>
              <div class="agreement-tips">
                发布即表示您已同意我们的<a href="http://www.pai2345.com/web/Findworks/agreement" target="_blank">原创条款</a>
              </div>
              <div class="submit-item">
                <a class="btn"  @click="submitForm()" href="javascript:;">提交</a>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <Gallery :transShowGallery="isShowGallery" @changeGallery="hideGallery" :end="gourp_limit.end"/>
  </div>
</template>

<script>
    import Gallery from "@/components/Gallery.vue";
    import draggable from 'vuedraggable';
export default {
    components: {
        Gallery,
        draggable,
    },
  data() {
    return {
      uploadBg: require("@/static/res/images/img-bg.png"),
      dialogImageUrl: "",
      dialogVisible: false,
      id: this.$route.params.id,
      define: [],
      custom_fields: [],
      group: this.$route.query.group,
        // 是否同意条款
        isAgreement: false,
        isPromise: false,
      form: {
        mname: "",
        custom_fields: [],
        shoot_address:'',
        remark: "",
        shoot_daterange:'',
        shoot_date:'',
        delivery: false,
        type: [],
        resource: '',
      },
      rules: {
        mname: [
          { required: true, message: "作品名称不能为空", trigger: "blur" }
        ],
        // define: [
        //   { required: true, message: "自定义名称不能为空", trigger: "blur" }
        // ],
          remark: [{ required: true, message: "作品描述不能为空", trigger: "blur" }]
      },
      // 限制图片数量
      uploadImgLen: 0,
      fileList: [
        {
          percent: 0,
          url: "",
        }
      ],
      isUploaded: true,
        gourp_limit:{
            typestr:'',
            imgtype:[],
            size:'',
            end:2,
            start:1,
            is_open_psd:0,
        },
        msg:'',
        has_submit:true,
        isShowGallery: false,
        uploadList: [
          {
            id:1,
            img: "https://dy-allimage.oss-cn-hangzhou.aliyuncs.com/uploads/488/2b6/4882b6d6291043703a9cc29cbb065c33.jpg",
            desc:'特等奖5万，总奖金15.8万！“冠豸山水·价值连城”全国摄影大赛',
            tips:'特等奖5万，总奖金15.8万！“冠豸山水·价值连城”全国摄影大赛',
          },{
            id:2,
            img: "https://dy-allimage.oss-cn-hangzhou.aliyuncs.com/uploads/385/7f7/3857f77beb197967c7edc2a519ee273c.jpg",
            desc:'“活力长荡湖未来科创城"全国摄影作品展',
          },{
            id:3,
            img: "https://dy-allimage.oss-cn-hangzhou.aliyuncs.com/uploads/85b/c39/85bc3951d18a1a557e4498dfc64d5532.jpeg",
            desc:'“活力长荡湖未来科创城"全国摄影作品展',
          },{
            id:4,
            img: "https://dy-allimage.oss-cn-hangzhou.aliyuncs.com/uploads/611/b16/611b164f5442659da529054fe9946735.jpeg",
            desc:'“活力长荡湖未来科创城"全国摄影作品展',
          },{
            id:5,
            img: "https://dy-allimage.oss-cn-hangzhou.aliyuncs.com/uploads/656/dbe/656dbe6f653f535eb4777b4d69f00c11.jpg",
            desc:'“活力长荡湖未来科创城"全国摄影作品展',
          },
        ],
    };
  },
  methods: {
    //列表更新
    dragUpdate(){
      
    },
    //上移
    moveUpDragItem(index){
      if(index===0){
        return false
      }
      this.uploadList.splice(index - 1, 1, ...this.uploadList.splice(index, 1, this.uploadList[index - 1]));
    },
    //下移
    moveDownDragItem(index){
      if(index===this.uploadList.length-1){
        return false
      }
      this.uploadList.splice(index, 1, ...this.uploadList.splice(index + 1, 1, this.uploadList[index]));
    },
    //删除
    removeDragItem(index){
      this.uploadList.splice(index, 1);
    },
    triggerUpload() {
      document.getElementById("file_btn").click();
    },
    hideView() {
      this.isUploaded = false;
    },
      showGallery() {
          this.isShowGallery = true;
      },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    overLimit() {
      console.log("图片超过数量了");
    },
    del(idx) {
      this.fileList.splice(idx, 1);
      this.uploadImgLen--;
      this.fileList.push({
        percent: 0,
        url: ""
      });
    },
    initFileList(end) {
      var len = this.fileList.length;
      for (var j = len; j < end; j++) {
        this.fileList.push({
          percent: 0,
          url: ""
        });
      }
    },
      addFileList(arr){
        for (var i=0;i<arr.length;i++){
            this.fileList[i] = arr[i];
        }
      },
      hideGallery(arr) {
          this.isShowGallery = false;
          var len = arr.length;
          var count = this.uploadImgLen;
          if(len > 0){

              this.imageUrl = arr[0];
              var fase = true;
              var end = this.gourp_limit.end;
              if (end > 0) {
                  if (count + len > end) {
                      fase = false;
                      this.$message("您上传的图片超过上限" + end + "张，请调整后重试！");
                  }
              }
              if (fase) {
                  var that = this;
                  for (var i = 0; i < len; i++) {
                      that.fileList[this.uploadImgLen].url = arr[i];
                      that.fileList[this.uploadImgLen].percent = 2;
                      this.uploadImgLen++;
                      that.$forceUpdate();
                  }
              }
          }
      },
    addImg(e) {
      var filesArr = e.target.files;
      var len = filesArr.length;
      //需要根据后台来设置
      var filesize = this.gourp_limit.size;
      var end = this.gourp_limit.end;
      for (var j = 0; j < len; j++) {
        if (filesArr[j].size > filesize * 1024 * 1024) {
            this.$message("上传图片不能超过" + filesize + "M");
            break;
        }
          if(!this.tools.hasImgType(this.gourp_limit.imgtype,filesArr[j].type)){
              this.$message.error("上传图片只能是 "+this.gourp_limit.typestr+" 格式!");
              break;
          }
      }
      if (j == len) {
        let count = this.uploadImgLen;
        var fase = true;
        if (end > 0) {
          if (count + len > end) {
              fase = false;
              this.$message("您上传的图片超过上限" + end + "张，请调整后重试！");
          }
        }
        if (fase) {
          var that = this;
          for (var i = 0; i < len; i++) {
            that.fileList[that.uploadImgLen+i].percent = 1;
              that.tools.uploadaly(filesArr[i]).then(res=>{
                  if (res) {
                      that.fileList[that.uploadImgLen].url = res;
                      that.fileList[that.uploadImgLen].percent = 2;
                      that.uploadImgLen++;
                  } else {
                      console.log(res);
                  }
              })
            this.$forceUpdate();
          }
        }
      }
      e.target.value = '';
    },
    sucessUpload(res, file, fileList) {
      console.log(res, file, fileList);
    },
	
	 submitForm() {
	  let that = this;
         if(this.has_submit){
             this.has_submit = false;
             this.$refs["form"].validate(valid => {
                 if (valid) {
                     var arr = [];
                     var imgArr = this.fileList;
                     var fase = true;
                     if(this.form.mname == ''){
                         this.$message.error("作品名称不能为空");fase=false;
                     }
                     if(fase){
                         for(var i=0;i<imgArr.length;i++){
                             if(imgArr[i].percent == 2){
                                 arr.push(imgArr[i]);
                             }
                             if(imgArr[i].percent == 1){
                                 this.$message.error("图片上传中，请稍候");
                                 fase = false;
                                 break;
                             }
                         }
                     }
                     var start = 2;
                     if(this.start > 0){
                         start = this.gourp_limit.start;
                     }
                     if(fase && arr.length < start){
                         fase = false;
                         this.$message("您上传的图片超过至少" + start + "张，请调整后重试！");
                     }
                     if(fase && this.gourp_limit.end > 0){
                         if(arr.length > this.gourp_limit.end){
                             fase = false;
                             this.$message("您上传的图片超过上限" + this.gourp_limit.end + "张，请调整后重试！");
                         }
                     }
                     if(fase && this.gourp_limit.is_open_psd == 2){
                         if(this.form.shoot_address == ''){
                             this.$message.error("图片拍摄地必须填写");fase=false;
                         }
                     }
                     if(fase && this.define.length>0){
                         for (var n=0;n<this.define.length;n++){
                             if(this.form.custom_fields[n] == '' || this.form.custom_fields[n] == undefined){
                                 this.$message.error(this.define[n]+"必须填写");fase=false;
                                 break;
                             }
                         }
                     }
                     if(fase){
                         this.axios
                             .post("/match/join_multi",
                                 {
                                     work_id: this.$route.query.work_id,
                                     match_id: that.id,
                                     mname: this.form.mname,
                                     remark: this.form.remark,
                                     imgs: arr,
                                     group: this.group,
                                     custom_fields: this.form.custom_fields,
                                     shoot_address:this.form.shoot_address,
                                     is_finding:this.isAgreement,
                                     works_type:2,
                                     source:2
                                 }
                             )
                             .then(res => {
                                 if(res.data.code == 200){
                                     this.$confirm('投稿成功!', '确认信息', {
                                         distinguishCancelAndClose: true,
                                         confirmButtonText: '再投一稿',
                                         cancelButtonText: '返回我的作品'
                                     })
                                         .then(() => {
                                             var initUrl = window.location.href;
                                             initUrl = initUrl.replace("work_id","init");
                                             window.location.href = initUrl;
                                             window.location.reload();
                                         })
                                         .catch(action => {
                                             console.log(action);
                                             this.$router.push({
                                                 path: '/detail/'+ that.id+'/myworks'
                                             });
                                         });
                                 }else {
                                     this.$message(res.data.msg)
                                 }
                                 this.has_submit = true;
                             },err=>{
                                 this.has_submit = true;
                                 console.log(err);
                             });
                     }else {
                         this.has_submit = true;
                     }
                 }else {
                     this.has_submit = true;
                 }
             });
         }
	},
      init(){
          this.tools.getMatchDetail({id:this.id,group:this.group}).then(res=>{
              this.define = res.custom_fields;
              this.msg = res.match_limit.match_introduce;
              var params = res.gourp_limit;
              if(res.match_limit.is_app_show == 1){
                  this.isAgreement = true;
              }
              if(params){
                  this.gourp_limit = Object.assign(this.gourp_limit,params);
                  this.gourp_limit.size = params.file_limit;
                  this.gourp_limit.imgtype = params.file_limit_type;
                  this.initFileList(this.gourp_limit.end);
                  this.gourp_limit.typestr = this.gourp_limit.imgtype.toString();
              }
          })
      }
	
  },
  mounted() {
      if(this.$route.query.work_id){
          this.tools.getWorksDetail({work_id:this.$route.query.work_id}).then(res=>{
              this.form = res.form;
              this.uploadImgLen = res.imgs.length;
              this.id = this.form.m_id;
              this.group = this.form.group;
              this.fileList = res.imgs;
              this.init();
          })
      }else {
          this.fileList = [];
          this.init();
      }

  }
  
  
};
</script>

<style>
/* .contest-submit .info-box .left .info-item.textarea-box {
  align-items: top;
}
.contest-submit .info-box .left .textarea-box .label {
  vertical-align: top;
  display: inline-block;
  height: 150px;
} */
/* .contest-submit .info-box .left .info-item .label {
  min-width: 90px;
} */
.contest-submit .info-box .left .el-textarea__inner {
  width: 100%;
}
.el-form-item__label {
  font-size: 16px;
}
.contest-submit .info-box .left .is-error textarea {
  border-color: #f56c6c;
}
.error {
  line-height: 24px;
  height: 24px;
  color: #f56c6c;
}
.el-input__inner:focus,
.el-input__inner:hover {
  border-color: #ddd;
}
.el-textarea .el-input__count {
  line-height: 16px;
  right: 20px;
}
.el-upload--picture-card {
  display: none;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 120px;
  height: 120px;
  border: 1px solid #eee;
  border-radius: 0;
}
.contest-submit .info-box .left .info-item{
  text-align: center;
}
.contest-submit .left .el-form-item .el-checkbox__label,
.contest-submit .left .el-form-item .el-radio__label
{
  font-size: 16px!important;
}

.drag-list{
  max-height: 600px;
  overflow: auto;
}
.drag-list .drag-item-wrap{
  margin-bottom: 15px;
}
.drag-list .drag-item-wrap .tips{
  cursor: move;
  margin-top: 5px;
}
.drag-list .drag-item{
  display: flex;
  cursor: move;
  position: relative;
}
.drag-list .drag-item .img{
  width:120px;
  height:80px;
  cursor: move;
}
.drag-list .drag-item .img img{
  width:100%;
  height:100%;
  border-radius:5px;
  cursor: move;
}
.drag-list .drag-item .desc{
  flex: 1;
  padding: 0 30px 0 0;
  box-sizing: border-box;
  height: 80px;
  overflow: auto;
  text-align: justify;
  margin-left: 10px;
}
.drag-list .drag-item .desc .ipt{
  width:100%;
  height: 100%;
}
.drag-list .drag-item .desc .ipt .el-textarea__inner{
  width:100%;
  height: 100%;
  resize: none;
}
.drag-list .drag-item .desc .ipt .el-textarea__inner:focus{
  border-color: #ef7f48;
}
.drag-list .drag-item .desc::-webkit-scrollbar{
  width: 6px;
}
.drag-list .drag-item .desc::-webkit-scrollbar-thumb{
  background: #ccc;
}
.drag-list .drag-item .desc::-webkit-scrollbar-track-piece {
  background: #f6f6f6;
}
.drag-list .drag-item:hover{
}
.drag-list .chosenDragItem{
  box-sizing: border-box;
  overflow: hidden;
}
.drag-list .drag-item .btn-group{
  height: 80px;
  position: absolute;
  right: 0px;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f5f5f5;
  display: none;
}
.drag-list .drag-item .btn-group i{
  width:24px;
  height:24px;
  display: flex;
  justify-content:center;
  align-items: center;
  cursor: pointer;
  color: #333;
}
.drag-list .drag-item .btn-group i:hover{
  color: #ef7f48;
  background:#d2d2d2;
}
.drag-list .drag-item:hover .btn-group{
  display: flex;
}
.contest-submit .left .agree-l-b{
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin: 15px 0;
}
.contest-submit .left .agree-l-b .check{
  vertical-align: middle;
}
</style>
